export const formatName = (data) => {
    const {firstname, lastname, email} = data;

    if (firstname && lastname) {
        return `${firstname} ${lastname}`;
    }
    if (firstname) {
        return firstname;
    }
    if (lastname) {
        return lastname;
    }

    // Si ni firstname ni lastname ne sont présents, retourner l'email
    return email;
}
