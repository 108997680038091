<template>

  <div @click="changeCurrent">
    <slot/>
  </div>

  <a-modal ref="modalRef" v-model:open="open" :footer="false" :mask="false" :wrap-style="{ overflow: 'hidden' }"
           @ok="handleOk">

    <!--   ============== Le contenu du modal =============== -->
    <div class="content-article-details">
      <div class="modal-body pt-1">
        <div class="card-header pb-0 px-3">
          <h6 class="mb-0 modal-title">Informations sur l'article</h6>
        </div>
        <div class="card-body pt-4 p-3">
          <ul class="list-group">

            <!--        Details textuel de la commande-->
            <li
                class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg"
            >
              <div class="d-flex flex-column">
                <h6 class="mb-3 text-sm">Détails de l'article</h6>
                <span class="mb-2 text-sm">
              Nom:
              <span class="text-dark font-weight-bold ms-sm-1 text-sm">{{ store?.currentArticle?.name }}</span>
            </span>

                <div class="grid grid-cols-1 gap-2">
                   <span class="mb-2 text-sm">
                      Description:
                      <span class="text-dark ms-sm-1 text-xs font-weight-bold">
                        {{ store?.currentArticle?.description || 'Aucune description fournie par le client' }}
                      </span>
                   </span>
                </div>

                <p class="mb-2 text-sm">
                  Prix:
                  <span class="ms-sm-1 text-sm text-success font-weight-bold"
                  >{{ formatCurrency(Number(store?.currentArticle?.price )|| 0) || 'Pas encore défini' }}</span
                  > $
                </p>
                <span class="text-sm">
              Ajouté:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">{{
                  dayjs(store?.currentArticle?.createdAt).locale('fr').format('DD MMM YYYY')
                }}</span>
            </span>
              </div>
            </li>

            <li
                class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg"
            >
              <div class="d-flex flex-column">
                <h6 class="mb-3 text-sm">Images</h6>
                <div class="d-flex flex-row flex-wrap w-100">
                  <img :src="require('@/assets/img/icon.png')"
                       alt="Image de l'article" class="img-thumbnail">
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>


      <div class="w-100 d-flex align-items-center justify-content-center mb-4">
        <button class="btn btn-sm btn-dark float-right mb-0 d-lg-block w-75" type="button" @click="handleOk">Terminer
        </button>
      </div>
    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>


<script setup>
import dayjs from "dayjs";
import {ref} from "vue";
import {useDraggableModal} from "@/hook/draggableModal";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import formatCurrency from "../../middlewares/formatCurrency";

const props = defineProps(['article'])
const modalTitleRef = ref(null);
const {open, transformStyle, showModal, handleOk} = useDraggableModal(modalTitleRef);

const store = commandeStore()

// Changer d'article courant lors du clic sur le bouton d'ouverture du modal
const changeCurrent = () => {
  showModal()
  store.setCurrentArticle(props.article)
}
</script>
