<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
import Loading from "@/views/components/Loading.vue";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import DetailsArticle from "@/views/modals/DetailsArticle.vue";

const cStore = commandeStore();
const store = useStore();
const isRTL = computed(() => store.state.isRTL);

defineProps({
  title: {
    type: String,
    default: "Articles",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  articles: {
    type: Array,
  }
});

</script>
<template>
  <div class="card" style="min-height: 75vh;">
    <div class="d-flex justify-content-between p-3 pb-0 card-header">
      <h6 class="mb-0">{{ title }}</h6>
      <Loading v-if="loading"/>
    </div>

    <div v-if="articles?.length===0" class="d-flex justify-content-center align-items-center flex-column">
      <img alt="empty illustration"
           class="img img-fluid" src="@/assets/img/illustrations/humain-and-table-vide.jpg"/>
      <span class="text-gray my-3 text-sm">Aucun nouvel article actuellement</span>
    </div>

    <div v-else class="p-3 card-body">
      <ul :class="`list-group ${isRTL ? 'pe-0' : ''}`">
        <li
            v-for="( article, index) of articles"
            :key="index"
            :class="`mb-2 border-0 list-group-item cursor-pointer d-flex justify-content-between border-radius-lg
          ${isRTL ? 'pe-0' : 'ps-0'}`"
            @click="cStore.setCurrentArticle(article)"
        >
          <div class="d-flex align-items-center">
            <div
                :class="`text-center shadow icon icon-shape icon-sm bg-gradient-${background} ${
                isRTL ? 'ms-3' : 'me-3'
              }`"
            >
              <i class="fas fa-check"></i>
            </div>

            <details-article :article="article">
              <div class="d-flex flex-column">
                <h6 class="mb-1 text-sm text-dark">{{ article?.name }}
                  <a-tooltip title="Commande d'où vient cet article">
                    <router-link class="mb-0 text-xs text-truncate font-weight-bold" style="width: 12rem"
                                 to="details" @click="cStore.setCurrentId(article?.commande?.id)">
                      <a-tag v-if="article.commande" color="blue">
                        {{ article?.commande?.title }}
                      </a-tag>
                    </router-link>
                  </a-tooltip>
                </h6>
                <span class="text-xs text-truncate" style="max-width: 24vw">{{
                    article?.description
                  }} {{ article?.description }}{{ article?.description }}{{ article?.description }}</span>
              </div>
            </details-article>

          </div>
          <div class="d-flex">
            <button
                class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"
            >
              <i
                  :class="`ni ${isRTL ? 'ni-bold-left' : 'ni-bold-right'}`"
                  aria-hidden="true"
              ></i>
            </button>
          </div>
        </li>
      </ul>
    </div>

  </div>
</template>
