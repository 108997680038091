<script setup>
import Loading from "@/views/components/Loading.vue";
import {usePayements} from "@/services-apis/payements/hooks.payements";
import PaniersHeaderPage from "./components/PaniersHeaderPage.vue";

const {isLoading} = usePayements()
</script>
<template>
  <div class="py-4 container-fluid">
    <PaniersHeaderPage/>

    <div class="row">
      <div class="col-12">
        <!--        Sous routes qui affiche par défaut
                <products-table/>
        -->
        <router-view/>

        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>
