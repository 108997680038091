// Changer le price de l'article
import {userStore} from "@/store/pinia-stores/user.store";
import {productStore} from "../../store/pinia-stores/products-store";
import api from "../axios-init";

export const createProduct = (data, files, callback, errorSave) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const formData = new FormData()
    // Ajouter chaque fichier dans formData
    files.forEach(file => {
        // Vérifier si file.originFileObj existe pour accéder au fichier brut
        formData.append('images', file.originFileObj || file);
    });

    // Parcourir chaque clé et valeur du JSON et ajouter à FormData
    Object.entries(data).forEach(([key, value]) => {
        // Ajouter chaque champ au FormData
        formData.append(key, value !== null ? value : '');  // Remplacer les valeurs null par des chaînes vides
    });

    const store = productStore()

    store.setIsLoading(true)

    api.post(`/admin/products/create`, formData, {headers}).then(res => {
        callback && callback()
        console.log(res)

        // Arrêter le chargement
        store.setIsLoading(false)

    }).catch(err => {
        callback && callback()
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err?.response?.data.message || ''
        errorSave(msg)
        console.log({err})

    })
}
