import {defineStore} from "pinia";

const currId = sessionStorage.getItem('ccid')
const CURRENT_ID = currId ? JSON.parse(currId) : currId

export const productStore = defineStore('productStoreId', {

    state: () => {
        return {
            currentId: CURRENT_ID,
            isLoading: false,
        }
    },

    actions: {
        // Changer l'id de la commande actuelle
        setCurrentId(id) {
            this.currentId = id
            sessionStorage.setItem('ccid', JSON.stringify(id))
        },

        // Chargement
        setIsLoading(load) {
            this.isLoading = load
        },

    }
})
