<script setup>
import {computed, ref} from "vue";
import router from "@/router";
import validator from "validator";
import {ShrinkOutlined} from '@ant-design/icons-vue'
import {useJustificationsCartsPayement} from "../../services-apis/payements/hooks.payements";
import UserJustificationsCartPayementsTable from "./UserJustificationsCartPayementsTable.vue";

const search = ref('')
const activeKey = ref(0)
const {justifications} = useJustificationsCartsPayement()

// Le seul à afficher lorsqu'on active le mode : N'afficher qu'un seul
const oneUser = ref(null)

// Afficher tous les clients
const showAllUser = (payement) => {
  let index = justifications.value?.findIndex((item) => item.user === payement?.user)
  // On annule le mode oneUser en mettant sa value sur "null"
  oneUser.value = null
  activeKey.value = null
  router.push('/facturations')
  setTimeout(() => activeKey.value = index, 300)
}

// Les configurations de la pagination
const currentPage = ref(1);
const lenPage = computed(() => {
  const totalItems = justifications.value?.length || 0;

  if (totalItems <= 5) {
    // Si on a 5 éléments ou moins, on affiche tout sur une seule page
    return 1;
  }

  // Pour les éléments au-delà de 5 : on calcule le nombre de pages restantes après avoir affiché 5 éléments sur la première page
  const remainingItems = totalItems - 5;

  // Le nombre de pages nécessaires après la première page (avec 10 éléments par page)
  // On multiplie par 10 pour s'assurer que le composant pagination collecte bien toutes les données afin d'afficher un bon nombe des pages
  const additionalPages = Math.ceil(remainingItems / 10) * 10;

  // 1 page pour les 5 premiers éléments + pages supplémentaires
  return additionalPages + 1;
});
const paginatedPayments = computed(() => {
  const totalPayments = justifications.value || [];
  const activePage = currentPage.value;

  if (activePage === 1) {
    // Première page : afficher les 5 premiers éléments
    return totalPayments.slice(0, 5);
  } else {
    // Autres pages : afficher 10 éléments par page
    const start = 5 + (activePage - 2) * 10;
    const end = start + 10;
    return totalPayments.slice(start, end);
  }
});

const filteredPayementLines = computed(() => oneUser.value ? paginatedPayments.value?.filter(item => (item === oneUser.value)) :
    paginatedPayments.value?.filter(item => (item ===
            validator.contains(item?.user?.firstname, search.value, {ignoreCase: true})
            ||
            validator.contains(item?.user?.lastname, search.value, {ignoreCase: true})
            ||
            validator.contains(item?.user?.email, search.value, {ignoreCase: true})
        )
    ))

</script>

<template>
  <div class="card mb-4 border-radius-md">

    <div class="d-flex justify-content-between">
      <div class="card-header pb-0">
        <h6>Justifications de paiement des paniers</h6>
      </div>

      <a-input
          v-model:value="search"
          class="m-3" placeholder="Trouver un client"
          style="width:18vw;"
          type="text"
      />

    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <!-- Si aucun paiement n'est trouvé -->
      <div v-if="filteredPayementLines?.length === 0" class="text-center">
        <div>
          <img alt="" class="img-fluid pointer-none" src="@/assets/img/paye.jpg" style="height: 300px; width: 300px"/>
          <p class="text-normal text-sm text-dark">Aucune justification de paiement enregistrée pour le moment</p>
        </div>
      </div>

      <div v-else class="table-responsive p-0" style="min-height: 55vh;">

        <!-- Section dépliable pour chaque paiement -->
        <a-collapse v-model:activeKey="activeKey" :bordered="false" accordion class="bg-white" ghost>

          <a-collapse-panel v-for="(payement, index) in filteredPayementLines" :key="index"
                            :show-arrow="false"
                            class="px-2">

            <!-- ============= Le header ============= -->
            <template #header>
              <div
                  :class="['d-flex flex-row justify-content-between w-100 px-2 border-radius-lg', Number(activeKey) === Number(index) ? 'bg-item-collapse-active' : 'bg-white']">

                <div class="d-flex">

                  <div class="m-2 bg-white shadow-sm p-2 rounded-circle d-flex justify-content-center"
                       style="height: 33px; width: 33px"
                       @click="oneUser &&showAllUser(payement)">
                    <ShrinkOutlined v-if="oneUser"/>
                    <i v-else class="fas fa-user"></i>
                  </div>

                  <div class="my-auto">
                    <h6 class="mb-0 text-sm text-truncate" style="width: 10rem">{{ payement?.user?.firstname }}
                      {{ payement?.user?.lastname }}</h6>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="my-auto">
                    <p class="mb-0 text-sm text-truncate text-secondary">
                      {{ payement?.paiements?.length }} {{
                        payement?.paiements?.length < 2 ? 'ligne' : 'lignes'
                      }}</p>
                  </div>

                </div>
              </div>
            </template>
            <!-- =============================================-->

            <!--             Contenu et détails dans un tableau -->
            <UserJustificationsCartPayementsTable :itemJustificationCart="payement" :justOneUser="oneUser"
                                                  :showAllUser="()=>showAllUser(payement)"
                                                  :showOnlyOneUser="()=>showOnlyOneUser(payement)"/>

          </a-collapse-panel>
        </a-collapse>

      </div>


      <!-- Les pages pour les utilisateurs (clients) lorsqu'ils sont nombreux -->
      <div class="d-flex justify-content-center border-top my-1 py-2">
        <a-pagination v-model:current="currentPage" :total="lenPage" size="small"/>
      </div>

    </div>
  </div>


</template>
