import {defineStore} from "pinia";

const currId = sessionStorage.getItem('ccid')
const CURRENT_ID = currId ? JSON.parse(currId) : currId

export const commandeStore = defineStore('commandeStoreId', {

    state: () => {
        return {
            currentId: CURRENT_ID,
            currentArticle: null,
            current: undefined,
            isLoading: false,

            // Pour voir les résultats similés des prix
            isOpenModalTestResultForPackedCommande: false,

            // Succès de l'emballage d'une commande
            isOpenModalResultForPackedCommandeSuccess: false,

            // Modal pour configurer le type des forfaits pour les articles d'une commande
            isOpenModalConfigureFlatRatePackedCommande: false,
            dataErrorFlatRate: null,

            // Les configs avancées sur l'envoi d'une demande de création d'une commande emballée
            metadataPackedCommande: null,

            // Pour utiliser les données du résultat du test (similation)
            valuesTestResult: null

        }
    },

    actions: {
        // Changer l'id de la commande actuelle
        setCurrentId(id) {
            this.currentId = id
            sessionStorage.setItem('ccid', JSON.stringify(id))
        },

        // Changer l'id de la commande actuelle
        setCurrentArticle(article) {
            this.currentArticle = article
        },

        // Afficher le modal de résultat de Test des coûts pour les emballages des colis
        setIsOpenModalTestResultForPackedCommande(value) {
            this.isOpenModalTestResultForPackedCommande = value
        },

        // Afficher le modal de résultat après emballage réussi
        setIsOpenModalResultForPackedCommandeSuccess(value) {
            this.isOpenModalResultForPackedCommandeSuccess = value
        },

        // Afficher le modal pour configurer le type des forfaits pour les articles d'une commande
        setIsOpenModalConfigureFlatRatePackedCommande(bool, data) {
            this.isOpenModalConfigureFlatRatePackedCommande = bool
            if (data) this.dataErrorFlatRate = data
        },

        // Stocker les valeurs du résultat de test de prix de l'emballage colis d'une commande
        setValuesTestResult(value) {
            this.valuesTestResult = value
        },

        // Changer l'id de la commande actuelle
        setCurrent(commande) {
            this.current = commande
        },

        // Mettre à jour les données sur la configuration de création d'une commande emballé
        setMetadataPackedCommande(config) {
            this.metadataPackedCommande = config
        },

        // Chargement
        setIsLoading(load) {
            this.isLoading = load
        },

    }
})
