<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

// state
const isRTL = computed(() => store.state.isRTL);
const darkMode = computed(() => store.state.darkMode);

// mutations
const toggleConfigurator = () => store.dispatch('toggleNotificationVisibility');
const toggleDarkMode = () => {
  const newDarkModeStatus = !darkMode.value;
  store.commit('toggleDarkMode', newDarkModeStatus);
  store.dispatch('toggleSidebarColor', newDarkModeStatus ? 'bg-default' : 'bg-white');
};

</script>

<template>
  <div class="fixed-plugin">
    <a
        class="px-3 py-2 text-dark position-fixed custom-fixed-button"
        @click="toggleConfigurator"
    >
      <i class="py-2 fas fa-bell"></i>
    </a>
    <div class="shadow-lg card">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="" :class="isRTL ? 'float-end' : 'float-start'">
          <h5 class="mt-3 mb-0">Notifications</h5>
          <p>Parcourez ces options</p>
        </div>
        <div
            class="mt-4"
            @click="toggleConfigurator"
            :class="isRTL ? 'float-start' : 'float-end'"
        >
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <hr class="my-1 horizontal dark"/>
      <div class="pt-0 card-body pt-sm-3">
        <!-- Sidebar Backgrounds -->
        <div>
          <h6 class="mb-0">Couleurs des boutons de la sidebar</h6>
        </div>
        <!-- Sidenav Type -->
        <div class="mt-3">
          <h6 class="mb-0">Type de la sidebar</h6>
          <p class="text-sm">Choisi entre ces deux types celui qui conviendra</p>
        </div>

        <hr class="horizontal dark my-4"/>
        <div class="mt-2 mb-5 d-flex">
          <h6 class="mb-0" :class="isRTL ? 'ms-2' : ''">Thème blanc / Thème sombre</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
                class="form-check-input mt-1 ms-auto"
                type="checkbox"
                :checked="darkMode"
                @click="toggleDarkMode"
            />
          </div>
        </div>

        <a
            class="btn btn-outline-dark w-100"
            target="_blank"
            href="http://www.centralachat.com"
        >Voir le site de central achat</a
        >

      </div>
    </div>
  </div>
</template>
