<template>
  <div class="clearfix">

    <h4 class="font-weight-bold">Sélectionner les fichiers (3 fichiers au max.)</h4>
    <a-upload
        v-model:file-list="fileList"
        :max-count="3"
        action=""
        list-type="picture-card"
        @change="handleChange"
        @preview="handlePreview"
    >
      <div v-if="fileList.length < 3">
        <FileAddOutlined/>
        <div style="margin-top: 8px">Sélectionner</div>
      </div>
    </a-upload>

    <!--    Preview de l'image-->
    <a-modal :footer="null" :mask="false" :open="previewVisible" @cancel="handleCancel">
      <template #title>
        <h4 class="font-weight-bold">Aperçu de l'image</h4>
        <span>{{ previewTitle }}</span>
      </template>
      <a :href="previewImage" target="_blank">
        <img :src="previewImage" alt="example" style="width: 100%"/>
      </a>
    </a-modal>

  </div>
</template>

<script setup>
import {ref} from 'vue';
import {FileAddOutlined} from '@ant-design/icons-vue'

const emit = defineEmits(['update:files']);

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const previewVisible = ref(false);
const previewImage = ref('');
const previewTitle = ref('');
const fileList = ref([]);

const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = '';
};

const handlePreview = async file => {
  if (!file.url && !file.preview) {
    file.preview = await getBase64(file.originFileObj);
  }
  previewImage.value = file.url || file.preview;
  previewVisible.value = true;
  previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
};

// Gérer le changement de fichier et émettre les fichiers vers le parent
const handleChange = ({fileList}) => {
  // Mettre à jour la liste de fichiers localement
  fileList.value = fileList;

  // Émettre l'événement avec les fichiers sélectionnés
  emit('update:files', fileList);
};

</script>

<style scoped>
.clearfix {
  clear: both;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.grid-item {
  width: 100%;
}

.uploaded-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}


/* Grid style for images */
.image-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 10px;
}

.grid-item-1,
.grid-item-2 {
  grid-column: span 1;
}

.grid-item-3 {
  grid-column: span 2; /* Full width on the bottom */
}

img {
  width: 100%;
  height: auto;
}

.progress-bar {
  background-color: #4b00e0;
}

button {
  color: #4b00e0;
}
</style>
