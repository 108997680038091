<script setup>
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import dayjs from "dayjs";
import {setCommandeIsAvailableNow} from "@/services-apis/commandes";
import {computed} from "vue";
import PackCommande from "@/views/modals/PackCommande.vue";
import PackCommandeFinishedSuccess from "@/views/modals/PackCommandeFinishedSuccess.vue";
import {MailOutlined, PhoneOutlined} from '@ant-design/icons-vue'
import SetPurchargeFeeCommande from "@/views/modals/SetPurchargeFeeCommande.vue";
import CancelCommandeWithReason from "@/views/modals/CancelCommandeWithReason.vue";
import SetPurchargeFeeForCanceledCommande from "@/views/modals/SetPurchargeFeeForCanceledCommande.vue";
import formatCurrency from "../../middlewares/formatCurrency";

const {commande, refetch} = useCommande()

const refreshData = async () => {
  await refetch()
}
// Dire ue c'est disponible maintenant
const availableCallback = (state) => {
  setCommandeIsAvailableNow({state: !state}, () => refreshData(), state ? 'Vous êtes revenu à l\'état précédent' : '')
}

// Le transport de la commande
const transportText = computed(() => !commande.value?.transport?.way ? 'Non défini' : commande.value?.transport?.way === 'BOAT' ? 'Maritime' : 'Aérien')
const mailToLink = computed(() => `mailto:${commande.value?.creator?.email}?subject=${encodeURIComponent(`Contact sur la commande ${commande.value?.title}`)}&body=${encodeURIComponent(`Bonjour client ${commande.value?.creator?.firstname || ''} ${commande.value?.creator?.lastname || ''},`)}`)

</script>

<template>
  <div class="card">
    <div class="card-header pb-0 px-3">
      <h6 class="mb-0 font-weight-bold">Informations sur la commande</h6>

      <a-tag v-if="commande?.isCompletePayed" color="green">En attente pour une livraison
      </a-tag>

      <!--                  Nouveau créée aujourd'hui-->
      <a-tag v-if="commande?.isNew" color="blue">Nouveau</a-tag>

      <!--                  Si la commmande est créée hier ou avant on affiche celui-ci-->
      <a-tag v-if="commande?.isNeverAllowed" color="blue">Nouveauté non vue</a-tag>

    </div>
    <div class="card-body pt-4 p-3">
      <ul class="list-group">
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm">Auteur (client)</h6>
            <span class="mb-2 text-sm">
              Nom complet:
              <span class="text-dark font-weight-bold ms-sm-1 text-sm">
                {{ commande?.creator?.firstname || 'Non défini' }} {{ commande?.creator?.lastname }}
              </span>
            </span>
            <span class="mb-2 text-sm">
              Adresse e-mail:
              <a :href="mailToLink"
                 class="text-link-blue ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                {{ commande?.creator?.email }}
                <MailOutlined class="pb-1"/>
              </a>
            </span>

            <span class="mb-2 text-sm">
              Téléphone:
              <a v-if="commande?.creator?.phoneNumber" :href="'tel:'+commande?.creator?.phoneNumber"
                 class="text-link-blue ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                {{ commande?.creator?.phoneNumber }}
                    <PhoneOutlined class="pb-1"/>
              </a>
              <span v-else class="text-dark ms-sm-1 text-sm font-weight-bold">
                Aucun numéro fournis
              </span>
            </span>

            <span class="mb-2 text-sm">
              ID.Unique:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ commande?.creator?.uuid }}
              </span>
            </span>

          </div>

          <div class="ms-auto text-end">

            <router-link class="btn btn-link text-dark text-xs px-3 mb-0" href="javascript:;" to='#'>
              <i aria-hidden="true" class="fas fa-info text-dark me-2"></i
              >Details
            </router-link>

          </div>
        </li>

        <!--        Détails textuel de la commande (informations avancées) -->
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm font-weight-bold">Détails avancés de la commande</h6>
            <span class="mb-2 text-sm">
              Titre:
              <span class="text-dark font-weight-bold ms-sm-1 text-sm">{{ commande?.title }}</span>
            </span>
            <span class="mb-2 text-sm">
              Description:
              <span class="text-dark ms-sm-1 text-xs font-weight-bold"
              >{{ commande?.description || 'Aucune description fournie par le client' }}</span>
            </span>

            <span class="mb-2 text-sm">
              Statut:
              <span :class="commande?.isCancel ? 'text-danger':'text-success'"
                    class="ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                {{ commande?.status }}
              </span>
              <a v-if="commande?.revokeReason" aria-controls="collapseDetailRevokeReason"
                 aria-expanded="false" class="btn border border-light btn-xs bg-white m-1 cursor-pointer"
                 data-bs-toggle="collapse" href="#collapseDetailRevokeReason"
                 role="button"
              >
              <i class="fas fa-question fa-sm"></i>
              </a>

            </span>

            <span v-if="commande?.purcharge_fee" class="mb-2 text-sm">
              Frais de services total:
              <span class="ms-sm-1 text-sm text-success font-weight-bold"
              >{{
                  commande?.purcharge_fee ? `${'$ ' + formatCurrency(Number(commande?.purcharge_fee || 0))}` : 'Non défini'
                }}</span
              >
            </span>

            <span class="text-sm">
              Date:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">{{
                  dayjs(commande?.createdAt).locale('fr').format('DD MMM YYYY')
                }}</span>
            </span>Fact
          </div>

          <div class="ms-auto text-end">

            <!-- Si le programme n'est pas approuver par les admins -->
            <button v-if="!commande?.isCancel &&commande?.isWaiting"
                    class="btn btn-link text-dark px-0 mb-0 "
                    type="button"
            >
              <set-purcharge-fee-commande/>
            </button>

            <!--======Lorsqu'on veut appliquer la revocation========= -->
            <button
                v-if="!commande?.isCancel"
                class="btn btn-link text-danger text-sm px-0 mb-0"
                type="button">
              <cancel-commande-with-reason/>
            </button>
            <!--======================================================-->

            <!--======Lorsqu'on veut annuler la revocation qui a été faite========= -->
            <button v-else class="btn btn-link text-dark px-0 mb-0 px-2 font-weight-normal" type="button">
              <set-purcharge-fee-for-canceled-commande/>
            </button>
            <!--======================================================-->

          </div>
        </li>

        <!-- ======Les details de la raison de revocation ===========-->
        <div id="collapseDetailRevokeReason" class="collapse">
          <div v-if="commande?.revokeReason" class="card card-body shadow-none mb-1">
            <span class="font-weight-bold text-dark text-xs">Pourquoi non acceptée ?</span>
            <p class="font-weight-bold text-danger text-md mt-1">{{ commande?.revokeReason?.title }}</p>
            <span class="font-weight-normal text-dark text-xs">{{ commande?.revokeReason?.slug }}</span>
          </div>

          <!--      --------------- Acceptée ensuite -------------------------------->
          <div v-if="commande?.acceptReason" class="card card-body shadow-none mb-1">
            <span class="font-weight-bold text-dark text-xs">Pourquoi acceptée ensuite ?</span>
            <p class="font-weight-bold text-success  text-md mt-1">{{ commande?.acceptReason?.title }}</p>
            <span class="font-weight-normal text-dark text-xs">{{ commande?.acceptReason?.slug }}</span>
          </div>
          <!---------------------------------------------------------------------------------->

        </div>
        <!--========================================================-->

        <!--        Les articles de la commande-->
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm font-weight-bold">Articles de la commande</h6>
            <span class="mb-2 text-sm">
             Disponibles:
              <span class="text-dark font-weight-bold ms-sm-1 text-sm"
              >{{ commande?.articleAvailable }} article(s)
                <!--                <span class="text-dark font-weight-normal ms-sm-1 text-sm">($ 22,34 à payer)</span>-->
              </span>
            </span>
            <span class="mb-2 text-sm">
              En attente:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ commande?.articles.length - commande?.articleAvailable }} article(s)
              </span>
            </span>

            <span class="mb-2 text-sm">
              Total:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold"
              >{{ commande?.articles.length }} article(s)</span
              >
            </span>

            <span class="text-sm">
             Modifiez le prix d'un article pour le rendre disponible au client qui le demande.
            </span>
          </div>
          <!--          <div class="ms-auto text-end">

                      <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;">
                        <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i
                        >Edit
                      </a>
                    </div>-->
        </li>

        <!--        Section livraison-->
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm font-weight-bold">Informations sur la livraison</h6>

            <span class="mb-2 text-sm">
              Ces informations sont fournies par le client pour la logistique de sa commande, veuillez tenir compte de celles-ci lors de vos différentes manipulations.
            </span>

            <span class="text-sm mt-3">
              Transport d'expédition :
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ transportText || "(Inconnu)" }}
              </span>
            </span>
            <hr class="dashed">

            <span class="mb-2 text-sm">
              Pays:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ commande?.adresse?.country || " - " }}
              </span>
            </span>

            <span class="mb-2 text-sm">
              Ville:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ commande?.adresse?.city || " - " }}
              </span>
            </span>

            <span class="mb-2 text-sm">
              Adresse :
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ commande?.adresse?.addressComplet || " - " }}
              </span>
            </span>

            <hr class="dashed">

            <span class="mb-2 text-sm text-dark font-weight-bold">
             {{ !commande?.isCompletePayed ? "Aucune action pour l'instant" : "Quelques actions" }}
            </span>

            <div class="d-flex flex-row flex-wrap px-2">
              <PackCommande v-if="commande?.isCompletePayed"/>
              <button v-if="commande?.isCompletePayed"
                      class="btn btn-primary text-normal bg-success py-2 mx-1 border-radius-2xl text-sm"
                      style="max-width: 190px">Livrer maintenant
              </button>

              <button v-if="commande?.isCompletePayed"
                      class="btn btn-primary text-normal bg-success py-2 mx-1 border-radius-2xl text-sm"
                      @click="availableCallback(commande?.isAvailableNow)">
                {{ commande?.isAvailableNow ? 'Pas encore disponible' : 'Rendre disponible' }}
              </button>


            </div>

          </div>

          <div class="ms-auto text-end">

            <!--            <router-link class="btn btn-link text-dark text-xs px-3 mb-0" href="javascript:;" to='#'>
                         Plus
                        </router-link>-->

          </div>
        </li>

      </ul>
    </div>
  </div>

  <!--  Les modals -->
  <!--  Modal pour dire que la commande a bien été emballée avec succès avec les détails du conditionnement-->
  <PackCommandeFinishedSuccess/>

</template>
