import {userStore} from "@/store/pinia-stores/user.store";
import api from "@/services-apis/axios-init";
import {useQuery} from "@tanstack/vue-query";

// Utiliser tous les articles
export const useProducts = () => {

    // Tous les articles
    const getProducts = async () => {
        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        // console.log({token})
        const res = await api.get('/admin/products/', {headers})
        return res?.data?.data || []
    }

    const {
        data: products, isPending, isLoading, isError, error, refetch
    } = useQuery({
        queryKey: ['get-all-products'], queryFn: getProducts, refetchInterval: 5000
    })

    // console.log({commandes})

    return {
        isPending, isLoading, isError, products, error, refetch
    }
}

