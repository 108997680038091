<script setup>
import ArgonButton from "@/components/FormButton.vue";
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import dayjs from "dayjs";
import ArticleItemBtn from "@/views/components/ArticleItemBtn.vue";
import {payementStore} from "@/store/pinia-stores/payements-store";
import router from "@/router";
import {usePayement} from "@/services-apis/payements/hooks.payements";
import MessagesChats from "@/views/modals/MessagesChats.vue";
import {useMessageByCommande} from "@/services-apis/messages/hooks.messages";
import ItemMessage from "@/views/components/ItemMessage.vue";
import UpdateMessage from "@/views/modals/UpdateMessage.vue";
import {useTypeText} from "@/hook/frontHooks";
import {commandeStore} from "../../store/pinia-stores/commande-store";
import {payementReactive} from "../../reactiveState/payements";
import formatCurrency from "../../middlewares/formatCurrency";

const {commande} = useCommande()
const {messages} = useMessageByCommande(commande.value?.id)

const {getTypeText} = useTypeText()

// Aller à un autre payement
const strPym = payementStore()
const {refetch} = usePayement()
const gotoOnePayement = async (pymnt) => {
  strPym.setCurrentId(pymnt?.id)
  await refetch()
  await router.push({name: 'details-payement'})
}

const store = commandeStore();

// Lorsqu'on veut lancer une nouvelle réquête de paiement depuis l'interface des details commande
const ifChoosed = async () => {
  store.setCurrentId(commande.value?.id)

  payementReactive.setModalCreateReqPayement(true)
  await refetch()
}

</script>
<template>
  <div class="card h-100 mb-4">
    <div class="card-header pb-0 px-3">
      <div class="row">
        <div class="col-md-12">
          <h6 class="mb-0">Transactions & articles</h6>
        </div>
      </div>
    </div>
    <div class="card-body pt-4 p-3">

      <!--      Les dernières transactions de la commande-->
      <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
        Discussions
      </h6>

      <!--      Aucun message présent-->
      <div v-if="messages?.length === 0" class="p-1 mb-4 col-lg-12 d-flex flex-column align-items-center">
        <span class="text-secondary text-sm my-3">Aucune discussion autour de cette commande pour l'instant</span>
      </div>

      <!--      Le dernier message reçu ou envoyé -->
      <div v-if="messages?.length>0">
        <ItemMessage :message="messages && messages.length > 0 ? messages[messages.length - 1] : []"/>
      </div>

      <!-- Les echanges sur la commande -->
      <div class="text-center">
        <MessagesChats>
          <a-button class="bg-white text-success text-xs px-3 mb-0" type="primary">
            Conversations
          </a-button>
        </MessagesChats>
        <!--======================================================-->
      </div>

      <!--      Les dernières transactions de la commande-->
      <div class="d-flex flex-row justify-content-between align-items-center w-100 mt-3">
        <h6 class="text-uppercase text-body text-xs font-weight-bolder">
          transactions
        </h6>

        <a-button class="border-radius-2xl bg-link-blue text-xs text-white" type="link" @click="ifChoosed">
          Nouveau paiement
        </a-button>
      </div>

      <!--      Aucun payement enregistré-->
      <div v-if="commande?.payements.length===0" class="text-center">
        <img alt="" class="img-fluid" src="../../assets/img/paye.jpg" style="height: 100px;width: 100px"/>
        <p class="text-normal text-sm text-dark">Aucun paiement enregistré pour cette commande</p>
      </div>

      <ul class="list-group">
        <li v-for="payement in commande?.payements"
            :key="payement.id"
            class="btn shadow-none text-normal border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
            @click="gotoOnePayement(payement)"
        >
          <div class="d-flex align-items-center">
            <argon-button
                class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
                color="success"
                size="sm"
                variant="outline"
            >
              <i aria-hidden="true" class="fas fa-arrow-up"></i>
            </argon-button>

            <div class="d-flex flex-column">
              <h6 class="mb-1 text-dark text-sm">{{ getTypeText(payement) }}</h6>
              <span class="text-xs text-start">{{ dayjs(payement?.createdAt).format('DD MMM, à HH:mm') }}</span>
            </div>

          </div>
          <div
              class="d-flex align-items-center text-success text-gradient text-lg font-weight-bold"
          >
            {{ payement?.devise?.symbole }} {{ formatCurrency(Number(payement?.totalPayed || 0)) }}
          </div>
        </li>

      </ul>

      <!--      Les articles de la commandes-->
      <h6 class="text-uppercase text-body text-xs font-weight-bolder my-3">
        articles
      </h6>
      <ul class="list-group">
        <li v-for="article  in commande?.articles" :key="article.id"
            class="btn shadow-card text-normal border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <article-item-btn :article="article"/>
        </li>

      </ul>
    </div>

    <!--  modifier un message déjà envoyé -->
    <UpdateMessage/>
  </div>

</template>
