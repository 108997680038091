<script setup>
import router from "../../router";
import {useRoute} from "vue-router";
import {computed} from "vue";
const route = useRoute()

const currentRouteName = computed(() => {
  return route.name;
});
console.log(currentRouteName)

</script>

<template>
  <div>
    <a-page-header sub-title="" title="Espace CA Pay" @back="() => $router.go(-1)">
      <template #extra>
        <a-button key="1" :type="currentRouteName==='accueil-facturations'? 'default':'text'" @click="router.push({name: 'Facturations'})">Paiements</a-button>
        <a-button key="2" :type="currentRouteName==='requete-payement'? 'default':'text'" @click="router.push({name: 'requete-payement'})">Requêtes</a-button>
        <a-button key="3" :type="currentRouteName==='justifications-payement'? 'default':'text'" @click="router.push({name: 'justifications-payement'})">Justifications</a-button>
      </template>
    </a-page-header>
  </div>
</template>

<style scoped>
.demo-page-header :deep(tr:last-child td) {
  padding-bottom: 0;
}
</style>
