<script setup>
</script>

<template>
  <div>
    <a-page-header sub-title="Magasin de la Centrale" title="Paniers commandés" @back="() => $router.go(-1)">
      <template #tags>
        <a-tag color="blue">Commandes faites par vos clients</a-tag>
      </template>

<!--      <template #extra>
        <a-button key="2">Demandes des clients</a-button>
      </template>-->

    </a-page-header>
  </div>
</template>

<style scoped>
.demo-page-header :deep(tr:last-child td) {
  padding-bottom: 0;
}
</style>
