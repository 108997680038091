// Accepter une commande en lançant sa recherche et en fixant le price de la recherche
import {userStore} from "@/store/pinia-stores/user.store";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import api from "@/services-apis/axios-init";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";

// Accepter une commande
export const setApprobationCommande = (data, refetch, messageSuccess, closeModal) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = commandeStore()

    store.setIsLoading(true)
    const idC = store?.currentId

    api.patch(`/admin/command/${idC}/setApprobation`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log({commande})

        // On change l'état de la commande actuelle
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // On ferme le modal
        closeModal()

        // Message du succès d'enregistrement user / admin
        toast.success(messageSuccess || msg || 'Demarches lancées', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 2000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible de lancer cette recherche pour l\'instant', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}

// Révoquer une commande
export const cancelApprobationCommande = (data, refetch, msgSuccess) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = commandeStore()

    store.setIsLoading(true)
    const idC = store?.currentId

    api.patch(`/admin/command/${idC}/cancelApprobation`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log({commande})

        // Arrêter le chargement
        store.setIsLoading(false)

        // Recharger la commande
        refetch()

        // Message du succès d'enregistrement user / admin
        toast.success(msgSuccess || msg || 'Recherche interrompue', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 2000,
            // onClose: () => router.push('/')
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible d\'intérrompred cette recherche pour l\'instant', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}

// Signaler qu'une commande est disponible maintenant
export const setCommandeIsAvailableNow = (data, refetch, msgSuccess) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = commandeStore()

    store.setIsLoading(true)
    const id = store?.currentId

    api.patch(`/admin/command/${id}/setAvailable`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log({commande})

        // Arrêter le chargement
        store.setIsLoading(false)

        // Recharger la commande
        refetch()

        // Message
        toast.success(msgSuccess || msg || 'Disponible maintenant', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 2000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible de rendre disponible cette commande', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}

// Emballer le colis d'une commande
export const createPackedCommande = (data, refetch, msgSuccess) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = commandeStore()

    store.setIsLoading(true)
    const id = store?.currentId

    api.post(`/admin/command/${id}/add-packed-details`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log({commande})

        // Arrêter le chargement
        store.setIsLoading(false)
        store.setValuesTestResult(res.data?.data)

        // S'il s'agit d'un test des prix
        if (res.data?.data?.resultTest) {
            store.setIsOpenModalTestResultForPackedCommande(true)
        } else {
            store.setIsOpenModalResultForPackedCommandeSuccess(true)
            // Recharger la commande
            refetch()
            toast.success(msgSuccess || msg || 'Commande emballée avec succès', {
                position: 'bottom-center',
                hideProgressBar: true,
                bodyClassName: 'body-toaster',
                toastStyle: Styles.toastStyle,
                autoClose: 2000,
            })
        }

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let code = err.response.data.errorCode
        let dataError = err.response.data.details
        let msg = err.response.data.message

        // Possibilité de faire un forfait
        if (code === "APPLY_FLAT_RATE_PRICE") {
            store.setIsOpenModalTestResultForPackedCommande(false)
            store.setIsOpenModalConfigureFlatRatePackedCommande(true, dataError)
        }
        toast.error(msg || 'impossible de rendre disponible cette commande', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}
