<script setup>
import {ref, watch} from "vue";
import {message} from 'ant-design-vue';
import AntImagePicker from "./AntImagePicker.vue";
import {createProduct} from "../../services-apis/products/products-request";
import Loading from "./Loading.vue";
import {productStore} from "../../store/pinia-stores/products-store";
import router from "../../router";
import {useProducts} from "../../services-apis/products/hooks.products";

const pstore = productStore()
const [messageApi, contextHolder] = message.useMessage();

// Champs du formulaire
const name = ref('');
const description = ref('');
const pricePerUnit = ref(''); // Initialiser avec 0 pour les calculs
const nombreUniteIndividuelles = ref('');
const discountPercentage = ref('');
const discountPrice = ref('');
const testDiscountPrice = ref('');
const writingDiscountPrice = ref(false); // Vérifie pour émettre un événement que nous sommes en train d'écrire dans le champs: "Prix de reduction"
const nombreDeCartonDisponibles = ref('');
const nombreUniteParCarton = ref('');
const pricePerCarton = ref('');
// Fichiers sélectionnés
const selectedFiles = ref([]);

// Vérifie si on enregistre un produit en promotion directement
const enablePromotion = ref(false);
// Vérifie si on enregistre un produit avec un stock par lot (cartons)
const enabledLots = ref(false);

const {refetch} = useProducts()
// Fonction pour soumettre le formulaire de création
const submitData = () => {

  // Validation des champs obligatoires
  if (!name.value || !description.value || !pricePerUnit.value || (!nombreUniteIndividuelles.value && !enabledLots.value)) {
    messageApi.error("Veuillez remplir tous les champs obligatoires. (*)");
    return;
  }

  // Validation des champs liés à la promotion
  if (enablePromotion.value && !discountPercentage.value) {
    messageApi.error("Veuillez indiquer le pourcentage réduction.");
    return;
  }

  // Validation des lots si activé
  if (enabledLots.value) {
    if (!nombreDeCartonDisponibles.value || !pricePerCarton.value || !nombreUniteParCarton.value) {
      messageApi.error("Veuillez indiquer la quantité et le prix d'un lot; mais aussi le nombre d'unité par lot");
      return;
    }
  }

  // Creation d'un produit
  createProduct({
    "name": name.value,
    "description": description.value,
    "pricePerUnit": Number(pricePerUnit.value),
    "pricePerCarton": Number(pricePerCarton.value),
    "cartonQuantity": Number(nombreDeCartonDisponibles.value),
    "unitsPerCarton": Number(nombreUniteParCarton.value),
    "individualUnits": Number(nombreUniteIndividuelles.value),
    "enablePromotion": enablePromotion.value ? 'OUI' : 'NON',
    "discountPercent": discountPercentage.value || Number(0),
    "quantityType": enabledLots.value ? 'CARTON' : 'INDIVIDUAL',
  }, selectedFiles.value, () => {
    messageApi.info('Produit ajouté')
    refetch()
    router.push('/shop')
  }, (msg) => {
    messageApi.error(msg || 'Création échouée')
  })
};

// Fonction pour calculer le prix après réduction
const calculerPrixApresReduction = () => {
  if (pricePerUnit.value && discountPercentage.value !== null) {
    // Limiter le pourcentage de réduction à -100% et 100%
    if (discountPercentage.value > 100) {
      discountPercentage.value = String(100);
    } else if (discountPercentage.value < -100) {
      discountPercentage.value = String(-100);
    }
    // Calculer le prix après réduction
    discountPrice.value = String(pricePerUnit.value * (1 - discountPercentage.value / 100));
  }
};

// Après avoir fini d'écrire le prix de la reduction pour recalculté les %
const writeFinished = (e) => {
  discountPrice.value = e.target.value
  calculerPourcentageReduction()
}

// Fonction pour calculer le pourcentage de réduction en fonction du prix après réduction
const calculerPourcentageReduction = () => {
  if (pricePerUnit.value > 0 && discountPrice.value !== null) {
    discountPercentage.value = String(((pricePerUnit.value - discountPrice.value) / pricePerUnit.value) * 100);

    // Limiter la valeur du pourcentage entre -100 et 100
    if (discountPercentage.value > 100) {
      discountPercentage.value = String(100);
    } else if (discountPercentage.value < -100) {
      discountPercentage.value = String(-100);
    }

    writingDiscountPrice.value = false
  }
};

// Watchers pour ajuster les valeurs
watch([discountPercentage, pricePerUnit], () => {
  if (enablePromotion.value && discountPercentage.value !== null) {
    calculerPrixApresReduction();
  }
});

// S'assurer que l'input % pourcentage ne dépasse jamais 100 % ou -100%
watch(discountPercentage, () => {
  if (discountPercentage.value > 100) {
    discountPercentage.value = String(100)
  } else if (discountPercentage.value < -100) {
    discountPercentage.value = String(-100);
  }
});
</script>

<template>
  <contextHolder/>

  <form :class="pstore.isLoading ? 'form-pointer':''" action="" class="card mb-4 border-radius-md"
        enctype="multipart/form-data" method="post"
        @submit.prevent="submitData">

    <div class="d-flex justify-content-between">
      <div class="card-header pb-0">
        <h6 class="font-weight-bold">Nouveau produit</h6>
      </div>
    </div>

    <div class="container my-4">
      <div class="d-flex flex-row flex-wrap gap-1">
        <!-- Section Informations initiales avec bordure -->
        <div class="section-border col-md-6">
          <h4 class="font-weight-bold mb-2">Informations initiales</h4>
          <div class="d-grid col-10">
            <h4 class="text-sm my-2 font-weight-bold">Nom du produit <span class="text-danger">*</span></h4>
            <a-input v-model:value="name" placeholder="Ex: Générateur 65 Volts / Voiture Essence"/>
          </div>

          <div class="d-grid col-10 my-2">
            <h4 class="text-sm my-2 font-weight-bold">Décrivez le produit en quelques lignes <span
                class="text-danger">*</span></h4>
            <a-textarea v-model:value="description" :auto-size="{ minRows: 4, maxRows: 5 }"
                        placeholder="Donnez la description"/>
          </div>

          <div class="d-grid col-10">
            <h4 class="text-sm my-2 font-weight-bold">Prix fixe du produit (prix unitaire) <span
                class="text-danger">*</span></h4>
            <p class="text-sm text-secondary">
              Ce prix est le montant exact d'une unité de ce produit !
              <span class="text-success">Une unité (pièce) de ce produit coûtera {{ pricePerUnit }} $</span>
            </p>
            <a-input-number v-model:value="pricePerUnit" :disabled="writingDiscountPrice" class="w-100"
                            placeholder="Prix par unité du produit (Ex: 1 produit X coûtera 10 $)"/>
          </div>

          <div class="d-grid col-10">
            <p class="text-sm text-danger text-bold mt-3">
              Ce produit est-il en promotion ?
              <a-button type="link" @click="enablePromotion=!enablePromotion">{{
                  enablePromotion ? "Oui" : "Non"
                }}
              </a-button>
            </p>
          </div>

          <div v-if="enablePromotion" class="d-grid col-10">
            <p class="text-success text-sm">Pourcentage ou coût de réduction <span class="text-danger">*</span></p>
            <div class="d-flex flex-row align-items-center flex-wrap gap-1">
              <a-input-number v-model:value="discountPercentage" :disabled="writingDiscountPrice" class="w-25"
                              placeholder="Le %"/>
              <!-- Champ pour saisir le prix après réduction -->
              <!--
                Le champ affiche la valeur de discountPrice tant que l'utilisateur n'est pas en train d'écrire.
                Une fois que l'utilisateur commence à écrire, il affiche testDiscountPrice à la place.

                Sachat que lorsqu'un entre en focus dans le input nous affectons direction le prix de la discountPrice dans le prix du testDisc...
              -->
              <a-input-number
                  :value="!writingDiscountPrice ? discountPrice : testDiscountPrice"
                  class="w-50"
                  placeholder="Prix après réduction"
                  @input="writingDiscountPrice = true"
                  @blur.stop="writeFinished"
                  @blur.capture="testDiscountPrice = discountPrice"
              />


            </div>
            <p class="text-xs my-2">
              Le prix de réduction est calculé en faisant le rapport entre le prix fixe et le % de réduction.
            </p>
          </div>
        </div>

        <!-- Section Stock avec bordure -->
        <div class="section-border w-45">
          <h4 class="font-weight-bold mb-2">Stock</h4>
          <div class="d-grid col-10">
            <p class="text-sm text-danger text-bold mt-3">
              Ce produit est-il susceptible d'être vendu en gros (par lot, carton, prix de gros...) ?
              <a-button type="link" @click="enabledLots=!enabledLots">{{ enabledLots ? "Oui" : "Non" }}</a-button>
            </p>
          </div>

          <div v-if="enabledLots" class="d-grid col-10">
            <strong class="text-success text-sm mb-1">Quantité & prix par lots (carton) <span
                class="text-danger">*</span></strong>
            <p class="text-sm text-secondary">
              Nbre des lots • <span class="text-dark">Nombre des lots disponibles.</span><br>
              Ex: 5 cartons(lots) des savons disponibles.
            </p>
            <p class="text-sm text-secondary">
              Unités par lot • <span class="text-dark">Le nombre d'unité de produit contenu dans un lot (carton).</span><br>
              Ex: 1 carton de 20 savons.
            </p>
            <div class="d-flex flex-row align-items-center flex-wrap gap-1">
              <a-input-number v-model:value="nombreDeCartonDisponibles" class="w-45" placeholder="Nbre des lots"/>
              <a-input-number v-model:value="nombreUniteParCarton" class="w-45" placeholder="Unités par lot"/>
            </div>
            <p class="text-sm text-secondary">
              Prix par lot • <span class="text-dark">Le prix d'1 lot (carton).</span><br>
              Ex: 1 carton de 20 savons vaut 20 $.
            </p>
            <div class="d-flex flex-row align-items-center flex-wrap gap-1">
              <a-input-number v-model:value="pricePerCarton" class="w-90" placeholder="Prix par lot (par carton)"/>
            </div>
            <div class="col-lg-12 mt-2">

              <p class="text-sm text-secondary">
                Ces informations concernent des produits tels que des clés USB à vendre en gros, des cartons des savons
                etc ...
              </p>
            </div>

          </div>

          <div class="d-grid col-10">
            <strong class="text-success text-sm mb-1 mt-3">Quantité de marchandises
              {{ enabledLots ? "(Hors carton)" : '' }}
              <span v-if="!enabledLots" class="text-danger">*</span></strong>
            <div class="d-flex flex-row align-items-center flex-wrap gap-1">
              <a-input-number v-model:value="nombreUniteIndividuelles" class="w-75"
                              placeholder="Quantité (nombre d'unités en stock)"/>
            </div>
            <p class="text-xs my-2" style="line-height: 20px;">
              Cette quantité est sauvegardée dans le système afin d'indiquer aux clients lorsqu'un produit est
              disponible, au bord de la rupture ou en rupture de stock.
            </p>
          </div>
        </div>

        <div class="section-border col-md-12">
          <!-- Image Grid -->
          <ant-image-picker
              @update:files="selectedFiles = $event"
          />
          <!--
          <ant-image-picker
                          @update:files="selectedFiles = $event"
                          @update:remote-files="imageUrls = $event"
                      />
          -->
        </div>

        <!--        Le chargement-->
        <div v-if="pstore.isLoading" class="col-md-12">
          <Loading/>
        </div>

        <div class="d-flex flex-row flex-wrap w-100 justify-content-end my-2">
          <a-button class="bg-danger-soft text-danger text-bold mx-2 text-sm border-radius-2xl" type="link">Annuler
          </a-button>
          <button class="bg-link-blue text-white text-bold mx-2 text-xs px-2 border-radius-2xl border-0"
                  type="submit">Sauvegarder
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<style scoped>
.section-border {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.form-pointer {
  pointer-events: none;
  opacity: .3;
}
</style>
