import { createStore } from "vuex";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: false,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",

    // Nouveaux états pour RightSideNotification
    isNotificationVisible: false,
    notificationType: '',
    hideNotificationButton: false,
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    sidebarMinimize(state) {
      let sidenav_show = document.querySelector("#app");
      if (state.isPinned) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      state.isNavFixed = !state.isNavFixed;
    },
    // Mutations pour RightSideNotification
    toggleNotificationVisibility(state) {
      state.isNotificationVisible = !state.isNotificationVisible;
    },
    setNotificationType(state, payload) {
      state.notificationType = payload;
    },
    // Mutation pour cacher le bouton de RightSideNotification
    toggleHideNotificationButton(state) {
      state.hideNotificationButton = !state.hideNotificationButton;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    // Action pour les notifications (ajoutée pour unification des appels)
    toggleNotificationVisibility({ commit }) {
      commit("toggleNotificationVisibility");
    },
    setNotificationType({ commit }, type) {
      commit("setNotificationType", type);
    },
    toggleHideNotificationButton({ commit }) {
      commit("toggleHideNotificationButton");
    },
  },
  getters: {},
});
