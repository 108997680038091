<template>

  <div
      v-show="layout === 'landing'"
      class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>

  <sidenav v-if="showSidenav"/>

  <main
      class="main-content position-relative max-height-vh-100 h-100 border-radius-sm"
  >
    <!-- Navbar -->
    <navbar v-if="showNavbar" :class="[navClasses]"/>

    <router-view/>

    <app-footer v-show="showFooter"/>

    <configurator
        :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
        :toggle="toggleConfigurator"
    />

    <right-side-notifications
        :class="[isNotificationVisible ? 'show' : '', hideNotificationButton ? 'd-none' : '']"
        :toggle="toggleNotificationVisibility"
    />


    <!--  Modal de choix d'une commande pour l'opération de réquête de paiement-->
    <choose-commande-for-req-payement/>

    <!--  Succès après l'opération d'envoi de la réquête de paiement, le modal qui permet de patientez pendant que le client accepte la requête de paiement -->
    <request-payement-sent-successfull/>

    <!--
      // Ce composant ouvre le modal qui permet de valider/sauvegarder un paiement après, que le client aît accepter la requête de paiement
      // Qui lui était envoyée
      -->
    <save-payement-after-acceptation-client/>

    <!--
      // Ce composant ouvre le modal qui permet voir que le client a annulée une demande de paiement sur une de ses commandes
      -->
    <is-cancel-payement-request/>

    <!--  Création d'une requete de payement-->
    <create-request-payement/>

    <!--  Le modal qui réagit lorsque une nouvelle requête de payement d'un client arrive -->
    <receiver-payement-requests-of-client/>

  </main>


  <!--  Gestion de connexion au serveur socket-->
  <ConnectionManager/>

</template>

<script setup>
import {computed} from 'vue';
import {useStore} from 'vuex';
import Sidenav from './examples/Sidenav';
import Configurator from '@/examples/Configurator.vue';
import Navbar from '@/examples/Navbars/Navbar.vue';
import AppFooter from '@/examples/Footer.vue';
import RightSideNotifications from "@/views/components/RightSideNotifications.vue";
import ConnectionManager from "@/socket-client/components/ConnectionManager.vue";
import SavePayementAfterAcceptationClient from "@/views/modals/SavePayementAfterAcceptationClient.vue";
import ChooseCommandeForReqPayement from "@/views/modals/ChooseCommandeForReqPayement.vue";
import RequestPayementSentSuccessfull from "@/views/modals/RequestPayementSentSuccessfull.vue";
import IsCancelPayementRequest from "@/views/modals/IsCancelPayementRequest.vue";
import CreateRequestPayement from "./views/modals/CreateRequestPayement.vue";
import ReceiverPayementRequestsOfClient from "./views/modals/ReceiverPayementRequestsOfClient.vue";

const store = useStore();
const isNavFixed = computed(() => store.state.isNavFixed);
const darkMode = computed(() => store.state.darkMode);
const isAbsolute = computed(() => store.state.isAbsolute);
const showSidenav = computed(() => store.state.showSidenav);
const layout = computed(() => store.state.layout);
const showNavbar = computed(() => store.state.showNavbar);
const showFooter = computed(() => store.state.showFooter);
const showConfig = computed(() => store.state.showConfig);
const hideConfigButton = computed(() => store.state.hideConfigButton);
const isNotificationVisible = computed(() => store.state.isNotificationVisible);

const toggleConfigurator = () => store.commit('toggleConfigurator');
const toggleNotificationVisibility = () => store.commit('toggleNotificationVisibility');

const navClasses = computed(() => {
  return {
    'position-sticky bg-white left-auto top-2 z-index-sticky':
        isNavFixed.value && !darkMode.value,
    'position-sticky bg-default left-auto top-2 z-index-sticky':
        isNavFixed.value && darkMode.value,
    'position-absolute px-4 mx-0 w-100 z-index-2': isAbsolute.value,
    'px-0 mx-4': !isAbsolute.value,
  };
});
</script>
